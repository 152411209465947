<template>
  <div>
    <page-component
      pageTitle="Coupons"
      :headers="couponHeaders"
      :items="filteredCoupons"
      :buttons="[
        { text: '+ Add New Coupon', to: { params: { form: 'AddEditForm' } } },
      ]"
    >
      <template v-slot:filters[0]>
        <v-checkbox
          v-model="includeReferrals"
          label="Include referrals"
          hide-details
          on-icon="mdi-checkbox-marked"
          off-icon="mdi-account-box-outline"
          dense
        />
      </template>
      <template v-slot:filters[1]>
        <v-checkbox
          v-model="showUsageColumn"
          label="Show usage"
          hide-details
          on-icon="mdi-checkbox-marked"
          off-icon="mdi-numeric-9-box-outline"
          dense
          @change="showUsage"
        />
      </template>
      <template v-slot:[`item.name`]="{ item }">
        <v-icon small class="mr-2 mt-0 mb-1">
          mdi-lock{{ !item.codeOnly ? "-off-outline" : "" }}
        </v-icon>
        {{ item.name }}
      </template>
      <template v-slot:[`item.rate`]="{ item }">
        {{ item.rate.type === "fixed" ? "$" : "" }}{{ item.rate.amount
        }}{{ item.rate.type === "percent" ? "%" : "" }}
      </template>
      <template v-slot:[`item.redemptionQty`]="{ item }">
        {{ item.redemptionQty === -1 ? "Unltd." : item.redemptionQty }}
      </template>
      <template v-slot:[`item.usage`]="{ item }">
        <v-progress-circular
          v-if="!discountedOrders.length"
          indeterminate
          size="16"
          color="primary"
          width="2"
          class="ml-2"
        ></v-progress-circular>
        <span v-else>
          {{
            discountedOrders.filter(order => order.appliedDiscount === item.id)
              .length
          }}
        </span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <nxg-action-btn type="edit" @click="editItem(item)" />
        <nxg-action-btn type="delete" item-type="COUPON" :item="item" />
      </template>
    </page-component>
    <add-edit-form @close="closeForm" />
  </div>
</template>

<script>
import AddEditForm from "./AddEditForm.vue"
import { mapState, mapActions } from "vuex"
import PageComponent from "../../shared/PageComponent.vue"

export default {
  name: "coupons",
  components: {
    AddEditForm,
    PageComponent,
  },
  data() {
    return {
      showUsageColumn: false,
      includeReferrals: false,
    }
  },
  computed: {
    ...mapState(["coupons", "restaurants", "discountedOrders", "currentUserRole"]),
    couponHeaders() {
      return [
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Code",
          value: "couponCode",
        },
        {
          text: "Discount",
          value: "rate",
        },
        {
          text: "Limit",
          value: "redemptionQty",
        },
        ...(this.showUsageColumn
          ? [
              {
                text: "Usage",
                value: "usage",
              },
            ]
          : []),
        {
          text: "Actions",
          value: "actions",
        },
      ]
    },
    filteredCoupons() {
      return this.coupons
        .filter(coupon => {
          if (coupon.isDeleted || !coupon.rate) return false
          if (this.includeReferrals) return true
          return !coupon.referralUserId && !coupon.referrerUserId
        })
        .filter(coupon => {
          if (this.currentUserRole !== "Admin") {
            return (
              coupon.restaurantIds?.length &&
              coupon.restaurantIds?.every(id =>
                this.restaurants?.map(restaurant => restaurant.id).includes(id)
              )
            )
          } else return true
        })
    },
  },
  methods: {
    ...mapActions(["fetchDiscountedOrders"]),
    async editItem(item) {
      await this.$store.commit("setItemToEdit", item)
      this.$router.push({ params: { form: "AddEditForm", id: item.id } })
    },
    showUsage() {
      if (!this.discountedOrders.length) {
        this.fetchDiscountedOrders()
      }
    },
    closeForm() {
      this.$store.commit("setItemToEdit", null)
      this.$router.push({ params: { form: null, id: null } })
    },
  },
}
</script>

<style></style>
