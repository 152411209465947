<template>
  <div>
    <form-dialog
      :visible="$route.params.form == 'AddEditForm'"
      :editMode="editMode"
      title="Coupon"
      :closeDisabled="closeDisabled"
      :awaitingResponse="awaitingResponse"
      :error="mainErrorMessage"
      class="mixed-form"
      @close="$emit('close')"
      @submit="submitHandle()"
    >
      <v-row justify="start" class="mt-2">
        <v-col cols="12">
          <h3>Basic Information</h3>
        </v-col>
      </v-row>
      <v-row v-if="coupon.referralUserId">
        <v-col>
          Earned for referring user:
          {{ getUserDisplayName(coupon.referralUserId) || coupon.referralUserId }}
        </v-col>
      </v-row>
      <v-row v-if="coupon.referrerUserId">
        <v-col>
          Earned for getting referred by user:
          {{ getUserDisplayName(coupon.referrerUserId) || coupon.referrerUserId }}
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="6" md="3">
          <v-text-field
            v-model="coupon.name"
            label="Display Name"
            :error-messages="getFieldError($v.coupon.name)"
            hide-details="auto"
            outlined
            dense
          />
        </v-col>
        <v-col cols="6" md="9">
          <v-radio-group
            v-model="coupon.type"
            :error-messages="getFieldError($v.coupon.type)"
            hide-details="auto"
            :readonly="editMode"
            class="mt-1"
            row
          >
            <v-radio
              v-for="couponType in couponTypes"
              :key="couponType.value"
              :value="couponType.value"
              :label="couponType.text"
            />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row justify="start" class="mt-8">
        <v-col cols="12">
          <h3>Discount</h3>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="6" md="3">
          <v-text-field
            v-model.number="coupon.rate.amount"
            :prefix="coupon.rate.type == 'percent' ? '%' : '$'"
            label="Discount"
            number
            outlined
            dense
            :error-messages="getFieldError($v.coupon.rate.amount)"
            hide-details="auto"
          />
        </v-col>
        <v-col cols="6" md="9">
          <v-radio-group
            v-model="coupon.rate.type"
            :error-messages="getFieldError($v.coupon.rate.type)"
            hide-details="auto"
            class="mt-1"
            row
          >
            <v-radio
              v-for="rateType in rateTypes"
              :key="rateType.value"
              :value="rateType.value"
              :label="rateType.text"
            />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row justify="start" class="mt-8">
        <v-col cols="12">
          <h3>Visibility</h3>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="12" class="pt-0">
          <v-switch
            v-model="coupon.isEnabled"
            :label="coupon.isEnabled ? `Coupon Enabled` : `Coupon Disabled`"
            :error-messages="getFieldError($v.coupon.isEnabled)"
            hide-details="auto"
            color="success"
          />
        </v-col>
      </v-row>
      <v-row v-if="coupon.type === 'code'" justify="start">
        <v-col cols="6" class="mt-n3">
          <v-radio-group
            v-model="coupon.codeOnly"
            :error-messages="getFieldError($v.coupon.codeOnly)"
            hide-details="auto"
            row
          >
            <v-radio :value="false" label="Show this coupon to all users" />
            <v-radio :value="true" label="Require user to enter code" />
          </v-radio-group>
        </v-col>
        <v-col v-if="coupon.codeOnly" cols="3">
          <v-text-field
            v-model="coupon.couponCode"
            label="Coupon Code"
            :error-messages="getFieldError($v.coupon.couponCode)"
            hide-details="auto"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-row v-if="coupon.type === 'code'" justify="start">
        <v-col cols="6" md="4" class="py-2">
          <v-switch
            v-model="limitedToPlatforms"
            :label="
              limitedToPlatforms
                ? `Visible on specific platforms`
                : `Visible on all platforms`
            "
            :error-messages="getFieldError($v.limitedToPlatforms)"
            hide-details="auto"
          />
        </v-col>
        <v-col v-if="limitedToPlatforms" cols="6" md="8" class="d-md-flex py-2">
          <v-checkbox
            v-for="platform in platforms"
            :key="platform.value"
            v-model="coupon.platforms"
            :label="platform.text"
            :value="platform.value"
            hide-details="auto"
            class="mr-8"
            multiple
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row justify="start" class="mt-8">
        <v-col cols="12">
          <h3>Limitations</h3>
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="6" md="4" class="py-2">
          <v-switch
            v-model="limitedToMinMaxSpend"
            :label="
              limitedToMinMaxSpend ? `Requires min/max spend` : `No min/max spend`
            "
            :error-messages="getFieldError($v.limitedToPlatforms)"
            hide-details="auto"
          />
        </v-col>
        <v-col v-if="limitedToMinMaxSpend" cols="3" class="py-2">
          <v-text-field
            v-model.number="coupon.minSpend"
            prefix="$"
            label="Min. required spend"
            number
            outlined
            :error-messages="getFieldError($v.coupon.minSpend)"
            hide-details="auto"
            class="mb-n4"
            dense
          />
        </v-col>
        <v-col v-if="limitedToMinMaxSpend" cols="3" class="py-2">
          <v-text-field
            v-model.number="coupon.maxSpend"
            prefix="$"
            label="Max. allowed spend"
            number
            outlined
            :error-messages="getFieldError($v.coupon.maxSpend)"
            hide-details="auto"
            class="mb-n4"
            dense
          />
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="6" md="4" class="py-2">
          <v-switch
            v-if="coupon.userSpecific"
            v-model="coupon.userSpecific"
            label="Can be used by any user"
            :error-messages="getFieldError($v.coupon.userSpecific)"
            hide-details="auto"
            readonly
          />
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="6" md="4" class="py-2">
          <v-switch
            v-model="redemptionUnlimited"
            :label="
              redemptionUnlimited
                ? `Can be reused infinitely`
                : `Limited # of redemptions`
            "
            :error-messages="getFieldError($v.redemptionUnlimited)"
            hide-details="auto"
          />
        </v-col>
        <v-col v-if="!redemptionUnlimited" cols="6" md="3" class="py-2">
          <v-text-field
            v-model.number="coupon.redemptionQty"
            label="Max # of redemptions"
            number
            outlined
            :error-messages="getFieldError($v.coupon.redemptionQty)"
            hide-details="auto"
            dense
          />
        </v-col>
      </v-row>
      <v-row justify="start">
        <v-col cols="6" md="4" class="py-2">
          <v-switch
            v-model="specificRestaurants"
            :label="
              specificRestaurants
                ? `Limited to items from certain restaurants`
                : `Applies to items from any restaurant`
            "
            :error-messages="getFieldError($v.specificRestaurants)"
            hide-details="auto"
          />
        </v-col>
        <v-col v-if="specificRestaurants" cols="6" md="8" class="d-md-flex py-2">
          <v-checkbox
            v-for="restaurant in restaurants"
            :key="restaurant.id"
            v-model="coupon.restaurantIds"
            :label="restaurant.name"
            :value="restaurant.id"
            hide-details="auto"
            class="mr-8"
            multiple
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row v-if="currentUserRole === `Admin`" justify="start" class="mt-8">
        <v-col cols="12">
          <h3>Financials</h3>
        </v-col>
      </v-row>
      <v-row v-if="currentUserRole === `Admin`" justify="start">
        <v-col cols="4">
          <v-text-field
            v-model.number="coupon.orgCoverage"
            prefix="%"
            :label="`Percentage of discount covered by ${settings.organizationName}`"
            number
            outlined
            messages="Otherwise covered proportionally by each restaurant."
            :error-messages="getFieldError($v.coupon.orgCoverage)"
            hide-details="auto"
          />
        </v-col>
      </v-row>
    </form-dialog>
  </div>
</template>

<script>
import FormDialog from "../../shared/dialogs/FormDialog.vue"
import { mapState } from "vuex"
import { required, numeric } from "vuelidate/lib/validators"
import getFieldError from "@/mixins/getFieldError"

export default {
  name: "add-edit-form",
  components: { FormDialog },
  mixins: [getFieldError],
  data() {
    return {
      closeDisabled: false,
      awaitingResponse: false,
      redemptionUnlimited: true,
      limitedToPlatforms: false,
      limitedToMinMaxSpend: false,
      specificRestaurants: false,
      platforms: [
        {
          value: "mobile",
          text: "Mobile",
        },
        {
          value: "web",
          text: "Web",
        },
        {
          value: "pos",
          text: "POS",
        },
        {
          value: "kiosk",
          text: "Kiosk",
        },
      ],
      coupon: { rate: { amount: null, type: "percent" } },
      rateTypes: [
        {
          text: "Percent",
          value: "percent",
        },
        {
          text: "Dollars",
          value: "fixed",
        },
      ],
      couponTypes: [
        {
          text: "General Promo",
          value: "code",
        },
        {
          text: "Staff Meal",
          value: "staffMeal",
        },
      ].filter(t => t.value !== "staffMeal" || this.settings?.posEnabled),
      mainErrorMessage: "",
    }
  },
  computed: {
    ...mapState([
      "itemToEdit",
      "settings",
      "restaurants",
      "currentUserRole",
      "customers",
    ]),
    editMode() {
      return this.itemToEdit != null
    },
  },
  validations() {
    return {
      coupon: {
        name: { required },
        type: { required },
        rate: {
          amount: { required },
        },
        minSpend: { numeric },
        maxSpend: { numeric },
        // redemptionQty: { numeric },
        orgCoverage: { numeric },
      },
    }
  },
  watch: {
    "$route.params.form": {
      handler(newValue) {
        if (newValue == "AddEditForm") {
          if (this.editMode) {
            this.prepEdit()
          }
        } else {
          this.clearFields()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.clearFields()
  },
  methods: {
    submitHandle() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.closeDisabled = this.awaitingResponse = true
        if (this.redemptionUnlimited) {
          this.coupon.redemptionQty = this.redemptionUnlimited
            ? -1
            : parseInt(this.coupon.redemptionQty || -1)
        }
        if (!this.limitedToPlatforms) {
          delete this.coupon.platforms
        }
        if (!this.limitedToMinMaxSpend) {
          this.coupon.minSpend = null
          this.coupon.maxSpend = null
        }
        if (!this.specificRestaurants || this.coupon.restaurantIds?.length === 0) {
          if (this.currentUserRole !== "Admin") {
            this.coupon.restaurantIds = this.restaurants.map(r => r.id)
          } else {
            delete this.coupon.restaurantIds
          }
        }
        this.$store
          .dispatch("updateCoupons", this.coupon)
          .then(() => {
            this.clearFields()
            this.$emit("close")
            this.$router.push({ params: { form: null, id: null } })
          })
          .catch(e => {
            this.mainErrorMessage = e
          })
          .finally(() => {
            this.closeDisabled = this.awaitingResponse = false
          })
      } else {
        console.error("Invalid", this.$v)
      }
    },
    prepEdit() {
      Object.assign(this.coupon, this.$store.state.itemToEdit)
      this.redemptionUnlimited = this.coupon.redemptionQty === -1
      this.limitedToPlatforms = this.coupon.platforms?.length > 0
      this.limitedToMinMaxSpend =
        this.coupon.minSpend != null || this.coupon.maxSpend != null
      this.specificRestaurants = this.coupon.restaurantIds?.length > 0
    },
    clearFields() {
      this.$v.$reset()
      this.coupon = {
        name: "",
        type: "code",
        isEnabled: true,
        rate: { amount: null, type: "percent" },
        couponCode: "",
        codeOnly: true,
        minSpend: null,
        maxSpend: null,
        redemptionQty: null,
        orgCoverage: 0,
      }
      this.redemptionUnlimited = true
      this.mainErrorMessage = ""
    },
    getUserDisplayName(uid) {
      return this.customers.find(u => u.uid === uid)?.displayName || ""
    },
  },
}
</script>

<style lang="scss">
.mixed-form {
  .v-label {
    font-size: 0.8rem;
  }
}
</style>
